<template>
  <div>
    <b-card
      no-body
      style="padding-bottom: 8px; padding-top: 10px"
      class="card-body"
    >
      <b-card-header
        style="font-size: 1.1em"
        class="d-flex justify-content-center"
      >
        <h4>{{ $t("Machine Learning Results") }}</h4>
      </b-card-header>
      <b-row class="list-rows">
        <b-col>
          <p class="device-props row">
            <b-col cols="" style="font-weight: bold" class="left-prop-column"
              ><h4>{{ $t("Devices") }}</h4></b-col
            >
            <b-col>
              <h4 style="font-weight: bold">{{ $t("True Positive  (TP)") }}</h4>
            </b-col>
            <b-col cols="" style="font-weight: bold" class="right-prop-column">
              <h4 style="font-weight: bold">
                {{ $t("False Positive (FP)") }}
              </h4>
            </b-col>
          </p>
        </b-col>
      </b-row>
      <b-row class="list-rows">
        <b-col>
          <p class="device-props row">
            <b-col cols="" class="left-prop-column">L101</b-col>
            <b-col>
              <div variant="success">
                <div style="">✔</div>
              </div>
            </b-col>
            <b-col cols="" class="right-prop-column"> </b-col>
          </p>
        </b-col>
      </b-row>
      <b-row class="list-rows">
        <b-col>
          <p class="device-props row">
            <b-col cols="" class="left-prop-column">L102</b-col>
            <b-col>
              <div variant="warning">
                <div style="">✔</div>
              </div>
            </b-col>
            <b-col cols="" class="right-prop-column"> </b-col>
          </p>
        </b-col>
      </b-row>
      <b-row class="list-rows">
        <b-col>
          <p class="device-props row">
            <b-col cols="" class="left-prop-column">L103</b-col>
            <b-col>
              <div variant="success"><div style=""></div></div>
            </b-col>
            <b-col cols="" class="right-prop-column"> ✔ </b-col>
          </p>
        </b-col>
      </b-row>
      <b-row class="list-rows">
        <b-col>
          <p class="device-props row">
            <b-col cols="" class="left-prop-column">L104</b-col>
            <b-col>
              <div variant="warning">
                <div style=""></div>
              </div>
            </b-col>
            <b-col cols="" class="right-prop-column"> ✔ </b-col>
          </p>
        </b-col>
      </b-row>
      <b-row class="list-rows">
        <b-col>
          <p class="device-props row">
            <b-col cols="" class="left-prop-column">L105</b-col>
            <b-col>
              <div variant="success"><div style=""></div></div>
            </b-col>
            <b-col cols="" class="right-prop-column"> ✔ </b-col>
          </p>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import { BCard, BRow, BCol, BBadge, BCardHeader } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    BCardHeader,
  },
  props: {
    data: [],
  },
  computed: {
    getCardColor() {
      if (this.data.rightValue < 50) {
        return "red";
      } else if (this.data.rightValue <= this.data.efficiencyLimit) {
        return "orange";
      } else {
        return "green";
      }
    },
  },
};
</script>
<style>
.device-props {
  margin-left: auto;
  text-align: justify;
  margin-bottom: clamp(2.5px, 1vw, 4px);
  margin-top: clamp(2.5px, 1vw, 4px);
  font-size: Clamp(1em, 1.8vw, 1.2em);
  color: black;
}

.left-prop-column {
  font-weight: 400;
}

.right-prop-column {
  margin-left: 1em;
}

.list-rows:not(:last-child) {
  border-bottom: 2px solid rgba(122, 122, 122, 0.1);
}

.device-props.row {
  align-items: center;
}
</style>
